<template>
  <b-card>
    <b-card-text class="text-center">
      <h3 class="m-0">
        Bienvenido al Portal de Gestiones
      </h3>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'Inicio',
  created() {
    localStorage.setItem('lastPath', this.$route.path)
  },
}
</script>

<style lang="scss" scoped>
</style>
