import { render, staticRenderFns } from "./Inicio.vue?vue&type=template&id=55a953c8&scoped=true"
import script from "./Inicio.vue?vue&type=script&lang=js"
export * from "./Inicio.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a953c8",
  null
  
)

export default component.exports